import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from '../services/nav.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  constructor(
    private navService: NavService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.navService.activeNav.next('info');
  }

  next() {
    this.router.navigateByUrl('/map-view');
  }

  clickCheckbox(event) {
    if (event.checked) {
      localStorage.setItem('info-shown', 'yes');
    } else {
      if (localStorage.getItem('info-shown')) {
        localStorage.removeItem('info-shown');
      }
    }
  }
}
