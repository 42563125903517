<div class="nav-container"
  *ngIf="activeNavigation !== 'splash' && activeNavigation !== 'ios' && activeNavigation !== 'info'">
  <div *ngIf="activeNavigation === 'map-view'">
    <button type="button" tabindex="-1" mat-icon-button color="primary" (click)="goToList()">
      <mat-icon>list</mat-icon>
    </button>
  </div>
  <div *ngIf="(activeNavigation === 'list-view' && !isActiveSearch) || (activeNavigation === 'favourites' && !isActiveSearch) && activeNavigation !== 'news'">
    <button type="button" tabindex="-1" mat-icon-button color="primary" (click)="goToMap()">
      <mat-icon svgIcon="map_icon"></mat-icon>
    </button>
  </div>
  <div [ngClass]="{'news-back-btn': activeNavigation === 'news'}"
    *ngIf="activeNavigation === 'search-view' || activeNavigation === 'settings-view' || activeNavigation === 'detail-view' || activeNavigation === 'news' || isActiveSearch">
    <button type="button" tabindex="-1" mat-icon-button color="primary" (click)="goBack()">
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
  </div>
  <div *ngIf="activeNavigation !== 'news'">
    <button type="button" tabindex="-1" mat-icon-button color="primary" (click)="goToFavourites()">
      <fa-icon class="star-icon" [icon]="faStar"></fa-icon>
    </button>
  </div>
  <div class="logo-wrap">
    <img width="80px" src="../../assets/images/fenaco_text.svg">
    <!--<button [disableRipple]="true" type="button" tabindex="-1" mat-icon-button class="info-button" color="primary" style="height: 24px; width: 24px;" (click)="openInfo()"><mat-icon style="font-size: 24px; height: 24px; width: 24px; padding-bottom: 30px; padding-left: 15px;">info_outline</mat-icon></button>-->
  </div>
  <div *ngIf="activeNavigation !== 'news'">
    <button mat-icon-button type="button" tabindex="-1" (click)="goToSettings()" color="primary">
      <mat-icon>settings</mat-icon>
    </button>
  </div>
  <div *ngIf="activeNavigation !== 'news'">
    <button mat-icon-button type="button" tabindex="-1" color="primary" (click)="goToSearch()">
      <mat-icon>search</mat-icon>
    </button>
  </div>
</div>