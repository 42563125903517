import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

import * as _ from 'lodash';

//services
import { FavouritesService } from 'src/app/services/favourites.service';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(
    private apiService: ApiService,
    private favouritesService: FavouritesService
  ) { }

  getMapMarkers(body: any) {
    return this.apiService.post('/api/locations/mapsearch', body, 'json', true).pipe(
      map(
        (data) => {
          const preparedData = this.prepareData(data);
          return preparedData;
        }
      )
    );
  }

  textSearch(body) {
    return this.apiService.post('/api/locations/textsearch', body, 'json', true).pipe(
      map(
        (data) => {
          const preparedData = this.prepareData(data.result);
          return { result: preparedData };
        }
      )
    );
  }

  advancedSearch(body) {
    return this.apiService.post('/api/locations/detailsearch', body, 'json', true).pipe(
      map(
        (data) => {
          const preparedData = this.prepareData(data.result);
          return { result: preparedData };
        }
      )
    );
  }

  getCantons() {
    return this.apiService.get('/api/cantons', 'json', true).pipe(
      map(
        (data) => {
          return data;
        }
      )
    );
  }

  getNews() {
    return this.apiService.get('/api/news', 'json', true).pipe(
      map(
        (data) => {
          return data;
        }
      )
    );
  }

  downloadFile(url: string) {
    return new Promise((resolve, reject) => {
      fetch(url).then(response => {
        const blob = response.blob();
        resolve(blob);
      }).catch(err => {
        reject(err);
      })
    })
  }

  private prepareData(data) {
    const favourites = [];
    const withVoucher = [];
    const others = [];

    for (const item of data) {
      if (this.favouritesService.favouritesIds.includes(item.id)) {
        favourites.push({ ...item, isFavourite: true });
      } else {
        (item.voucher.length) ? withVoucher.push(item) : others.push(item)
      }
    }

    return [].concat.apply([], [_.sortBy(favourites, ['company']), _.sortBy(withVoucher, ['company']), _.sortBy(others, ['company'])]);
  }
}
