import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MapViewComponent } from './map-view/map-view.component';
import { ListViewComponent } from './list-view/list-view.component';
import { DetailViewComponent } from './detail-view/detail-view.component';
import { SearchViewComponent } from './search-view/search-view.component';
import { NavigationComponent } from './navigation/navigation.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateService, TranslateLoader } from '@ngx-translate/core';
import { LOCATION_INITIALIZED, registerLocaleData } from '@angular/common';
import localeDeCh from '@angular/common/locales/de-CH';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { NavService } from './services/nav.service';
import { RequestService } from './services/request.service';
import { ApiService } from './services/api.service';

import { GoogleMapsModule } from '@angular/google-maps';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { AddIosComponent } from './add-ios/add-ios.component';
import { SettingsDialogComponent } from './dialogs/settings-dialog/settings-dialog.component';
import { InfoComponent } from './info/info.component';
import { SettingsComponent } from './settings/settings.component';
import { IosAddToHomescreenComponent } from './ios-add-to-homescreen/ios-add-to-homescreen.component';
import { ErrorDialogComponent } from './dialogs/error-dialog/error-dialog.component'
import { SwiperModule } from 'swiper/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FavouritesComponent } from './favourites/favourites.component';
import { LocationsListComponent } from './components/locations-list/locations-list.component';
import { DetailNewsComponent } from './detail-news/detail-news.component';

// @Injectable()
// export class MyHammerConfig extends HammerGestureConfig {
//   overrides = <any> {
//     swipe: { direction: Hammer.DIRECTION_ALL }
//   };
// }

// tslint:disable-next-line: typedef
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

// tslint:disable-next-line: typedef
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'de';
      translate.setDefaultLang(langToSet);
      translate.use(langToSet).subscribe(() => {
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}
registerLocaleData(localeDeCh);

@NgModule({
  declarations: [
    AppComponent,
    MapViewComponent,
    ListViewComponent,
    DetailViewComponent,
    SearchViewComponent,
    NavigationComponent,
    SplashScreenComponent,
    AddIosComponent,
    SettingsDialogComponent,
    InfoComponent,
    SettingsComponent,
    IosAddToHomescreenComponent,
    ErrorDialogComponent,
    FavouritesComponent,
    LocationsListComponent,
    DetailNewsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatInputModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    FormsModule,
    GoogleMapsModule,
    SwiperModule,
    HammerModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FontAwesomeModule,
  ],
  providers: [
    HttpClientModule,
    NavService,
    ApiService,
    RequestService,
    TranslateService,
    { provide: LOCALE_ID, useValue: 'de-CH' },
    // {
    //   provide: HAMMER_GESTURE_CONFIG,
    //   useClass: MyHammerConfig,
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
