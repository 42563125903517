<div style="margin-top: 100px">
  <div style="text-align: center;">
    <h2>{{ 'SETTINGS_TITLE' | translate }}</h2>
    <div style="margin-top: 25px;">
    <h4 style="color: #006a3a;">{{ 'SETTINGS_SUBTITLE' | translate }}</h4>
    </div>
    <div style="margin-top: 40px;">
      <mat-form-field style="display: block; margin-left: 15px; margin-right: 15px;">
        <mat-select name="lang" [(ngModel)]="selectedLang" (selectionChange)="change($event)">
          <mat-option *ngFor="let lang of langs" [value]="lang.value">
            {{lang.text}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
