<div style="margin-top: 120px; margin-left: 30px; margin-right: 30px;">
  <div style="text-align: center;">
    <p>{{ 'SEARCH_TEXT' | translate }}</p>
  </div>
  <div>
    <mat-form-field style="display: block;">
      <input matInput name="searchField" [(ngModel)]="body.search" placeholder="{{ 'SEARCH_INPUT' | translate }}" (keydown.enter)="searchEnter($event.target.value)">
    </mat-form-field>
  </div>
  <!-- <div>
    <mat-slide-toggle name="slider-lunch" [(ngModel)]="body.onlyluncheck" color="primary">{{ 'SEARCH_SLIDER' | translate }}</mat-slide-toggle>
  </div> -->
  <div *ngIf="!advancedOpen" style="text-align: center; margin-top: 25px;">
    <button [disabled]="!body.search" mat-button class="list-button" style="margin-right: 10px; width: 150px;" (click)="search()"><mat-icon style="margin-right: 10px;">search</mat-icon>{{ 'SEARCH_BUTTON' | translate }}</button>
  </div>
  <div class="advanced-search">
    <div class="advanced-button">
      <button mat-flat-button type="button" tabindex="-1" (click)="openAdvancedSearch()">{{ 'ADVANCED_SEARCH' | translate }}<mat-icon *ngIf="!advancedOpen">expand_more</mat-icon><mat-icon *ngIf="advancedOpen">expand_less</mat-icon></button>
    </div>
    <div *ngIf="advancedOpen">
      <div>
        <mat-form-field style="display: block;">
          <mat-label>{{ 'SEARCH_STATE' | translate }}</mat-label>
          <mat-select #state name="states" multiple [(ngModel)]="body.canton" disableOptionCentering>
            <button mat-icon-button (click)="state.close()" style="margin-left: calc(100% - 64px)"><mat-icon>close</mat-icon></button>
            <mat-option *ngFor="let state of states" [value]="state.value">
              {{state.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field style="display: block;">
          <input matInput name="zip" [(ngModel)]="body.zipcode" placeholder="{{ 'SEARCH_ZIP' | translate }}">
        </mat-form-field>
      </div>
      <div style="display: flex; justify-content: center; margin-bottom: 15px;">
        <mat-checkbox style="margin-right: 15px;" name="isHotel" [(ngModel)]="body.is_hotel" color="primary" (change)="changeBodyValue()">{{'SEARCH_IS_HOTEL' | translate}}</mat-checkbox>
        <mat-checkbox name="isSeminar" [(ngModel)]="body.is_seminar"  color="primary" (change)="changeBodyValue()">{{'SEARCH_IS_SEMINAR' | translate}}</mat-checkbox>
      </div>
      <div *ngIf="body.is_hotel">
        <mat-form-field style="display: block;">
          <mat-label>{{ 'SEARCH_SIZE' | translate }}</mat-label>
          <mat-select [(ngModel)]="body.hotel_size" disableOptionCentering>
            <mat-option *ngFor="let size of selectSize" [value]="size.value">
              {{ size.size | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-checkbox name="clients" color="primary" (change)="changeBodyValue()">{{'SEARCH_NO_FENACO' | translate}}</mat-checkbox>
      </div>
      <div style="text-align: center; margin-top: 25px;">
        <button mat-button class="list-button" style="margin-right: 10px; width: 150px;" (click)="searchAdvanced()"><mat-icon style="margin-right: 10px;">search</mat-icon>{{ 'SEARCH_BUTTON' | translate }}</button>
      </div>
    </div>
  </div>
  <div style="text-align: center; margin-top: 25px;" *ngIf="noData">
    <p>{{ 'NO_DATA_FOUND' | translate }}</p>
  </div>
</div>
