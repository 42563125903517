import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NavService } from '../services/nav.service';

@Component({
  selector: 'app-detail-news',
  templateUrl: './detail-news.component.html',
  styleUrls: ['./detail-news.component.scss']
})
export class DetailNewsComponent implements OnInit, OnDestroy {
  public currentNews;
  public media;

  //subscriptions
  private currentNews$: Subscription;

  constructor(
    private navService: NavService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.navService.activeNav.next('news');

    if (this.navService.currentNews.value) {
      this.currentNews$ = this.navService.currentNews.subscribe(res => {
        this.currentNews = res;
        if (this.currentNews.media && this.currentNews.media.length) {
          const [media] = this.currentNews.media;
          if (media.file_type === 'jpg') {
            this.media = media;
          }
        }
      })
    } else {
      this.router.navigateByUrl('map-view');
    }
  }

  ngOnDestroy(): void {
    if (this.currentNews$) this.currentNews$.unsubscribe();
  }

}
