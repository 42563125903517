import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from '../services/nav.service';
import { Location } from '@angular/common';
import { faStar } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  faStar = faStar;

  activeNavigation = ''
  isActiveSearch = false;

  constructor(
    private router: Router,
    private navService: NavService,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.navService.activeNav.subscribe(
      (data) => {
        this.activeNavigation = data;
      }
    );
    this.navService.searchActive.subscribe(
      (data) => {
        this.isActiveSearch = data;
      }
    )
  }

  goToList() {
    this.router.navigateByUrl('list-view');
  }

  goToSearch() {
    this.router.navigateByUrl('search');
  }

  goToMap() {
    this.router.navigateByUrl('map-view');
  }

  goToSettings() {
    this.router.navigateByUrl('/settings');
  }

  goToFavourites() {
    this.router.navigateByUrl('favourites');
  }

  goBack() {
    this.location.back();
  }
}
