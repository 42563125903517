<div class="aaths-instructions" *ngIf="!hidePopup">
  <div class="msg">
      {{ 'IOS_TEXT1' | translate }}
      {{ 'IOS_TEXT2' | translate }} <span class="ios-share"></span>
      {{ 'IOS_TEXT3' | translate }}<strong style="font-weight: 600"> {{ 'IOS_TEXT4' | translate }}</strong><span class="ios-add"></span>
  </div>
  <div class="close" (click)="onHidePopup()">
    <span class="ios-close"></span>
  </div>
</div>
