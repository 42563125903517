import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from '../services/nav.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {

  showIOSWindow = false;

  constructor(
    private router: Router,
    private navService: NavService
  ) { }

  ngOnInit(): void {
    this.navService.activeNav.next('splash');
    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.showIOSWindow = false;
    } else {
      this.showIOSWindow = true;
    }

    setTimeout(() => {
      if (localStorage.getItem('info-shown')) {
        this.router.navigateByUrl('/map-view');
      } else {
        this.router.navigateByUrl('/info');
      }
    }, 2000);
  }
}
