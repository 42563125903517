import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

//services
import { NavService } from 'src/app/services/nav.service';
import { FavouritesService } from 'src/app/services/favourites.service';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss']
})
export class FavouritesComponent implements OnInit, OnDestroy {
  displayItems: Array<any> = [];

  //subscriptions
  private favourites$: Subscription;

  constructor(
    private navService: NavService,
    private favouritesService: FavouritesService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.navService.searchActive.next(false);
    this.navService.activeNav.next('favourites');

    if (this.favouritesService.favouritesIds.length) {
      this.favourites$ = this.favouritesService.favourites.subscribe(res => {
        this.displayItems = res.filter(item => item.isFavourite);
      });
    } else {
      this.router.navigateByUrl('map-view');
    }

    this.favourites$ = this.favouritesService.favourites.subscribe(res => {
      this.displayItems = res.filter(item => item.isFavourite);
    });
  }

  ngOnDestroy(): void {
    if (this.favourites$) this.favourites$.unsubscribe();
  }
}
