<div
  style="display: flex; justify-content: center; margin-top: 50%; margin-left: calc(50% - 50px); z-index: 999; position: absolute;"
  *ngIf="!loadingComplete || !markersLoaded">
  <mat-spinner></mat-spinner>
</div>
<div id="mark-details" class="marker_overlay" [hidden]="!selectedMarker">
  <div *ngIf="selectedMarker" style="padding: 15px; text-align: center;">
    <div style="display: block;">
      <button mat-icon-button (click)="removeSelected()"
        style="position: absolute; font-size: 12px; right: 0px; top: -7px">
        <mat-icon style="font-size: 17px;">clear</mat-icon>
      </button>
    </div>
    <ng-container *ngIf="selectedMarker.group.length === 0">
      <div style="line-height: 16px; height: 32px; overflow: hidden; width: 80%; margin-left: 25px;">
        {{selectedMarker.company}}</div>
      <div *ngIf="selectedMarker" style="margin-bottom: 10px; margin-top: 10px;">
        <button mat-button class="map-button" (click)="showMap(selectedMarker)">
          <mat-icon style="margin-right: 10px;" svgIcon="route_icon_map"></mat-icon>{{ 'ROUTE_BUTTON' | translate }}
        </button>
      </div>
      <div *ngIf="selectedMarker">
        <button mat-button class="map-button" (click)="goToDetails(selectedMarker, null)">
          <mat-icon style="margin-right: 10px;">info_outline</mat-icon>{{ 'INFO_BUTTON' | translate }}
        </button>
      </div>
      <div *ngIf="selectedMarker">
        <button mat-button class="map-button fav-button" [ngClass]="{'favourite': selectedMarker.isFavourite}"
          style="margin-top: 10px;" (click)="toggleFavourite()">
          <fa-icon style="margin-right: 10px;" class="star-icon"
            [icon]="selectedMarker.isFavourite ? faStarSolid : faStarLight"></fa-icon>
          {{
          'LIKE_BUTTON' | translate }}
        </button>
      </div>
      <!-- <div *ngIf="selectedMarker && selectedMarker.lunchcheck"
        style="width: 49px; height: 49px; position: absolute; top: 70px; right: 10px;">
        <img *ngIf="selectedMarker.lunchcheck" src="../../assets/images/lunch_check_blue.svg">
      </div> -->
    </ng-container>
    <ng-container *ngIf="selectedMarker.group.length > 0">
      <div style="margin-bottom: 10px;">{{selectedMarker.group.length}} {{'MARKER_CLIENTS' | translate}}:</div>
      <div *ngIf="selectedMarker" style="margin-bottom: 10px; margin-top: 10px;">
        <button mat-button class="map-button" (click)="showMap(selectedMarker)">
          <mat-icon style="margin-right: 10px;" svgIcon="route_icon_map"></mat-icon>{{ 'ROUTE_BUTTON' | translate }}
        </button>
      </div>
      <div *ngIf="selectedMarker" style="margin-bottom: 15px;">
        <button mat-button class="map-button" (click)="goToDetails(selectedMarker, null)">
          <mat-icon style="margin-right: 10px;">info_outline</mat-icon>{{ 'INFO_BUTTON' | translate }}
        </button>
      </div>
      <div *ngFor="let g of selectedMarker.group" style="display: flex; margin-bottom: 15px;">
        <div style="line-height: 16px; height: 32px; overflow: hidden; width: 80%; margin-left: 25px;">
          {{g.company}}</div>
        <!-- <div><img *ngIf="g.lunchcheck" class="group_lunch" src="../../assets/images/lunch_check_blue.svg"></div> -->
      </div>
    </ng-container>
  </div>
</div>
<div>
  <mat-checkbox class="search-slider" color="primary" [disabled]="!markersLoaded" [(ngModel)]="onlyvouchers"
    defaultColor="#ffffff" (change)="markerChange($event)" ><span [innerHTML]="'SEARCH_SLIDER' | translate"></span></mat-checkbox>
</div>
<div id="map" class="map-content" visible="false">
  <!--<google-map height="100%" width="100%" [zoom]="zoom" [center]="center" [options]="options">
    <map-marker [position]="myLatLng" title="My Location" [options]="myOptions"></map-marker>
    <map-marker *ngFor="let marker of availableMarkers" [position]="marker.position" [label]="marker.label"
      [title]="marker.title" [options]="marker.options"></map-marker>
  </google-map>-->
</div>
<div class="legend">
  <div class="news-header">
    <div class="news-title">fenaco News</div>
    <div *ngIf="news.length" class="swiper-pagination"></div>
  </div>

  <swiper *ngIf="news.length" class="swiper" #swiper [config]="config">
    <ng-container *ngFor="let item of news">
      <ng-template swiperSlide>
        <div class="slide" (click)="goToNewsDetails(item)">
          <div class="title" [innerHtml]="item.title"></div>
          <div class="desc-wrap">
            <div class="description" [innerHtml]="item.short_desc"></div>
            <fa-icon class="arrow-icon" [icon]="faAngleRight"></fa-icon>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </swiper>
  <div *ngIf="isNewsLoaded && !news.length" class="no-news">{{ 'NO_NEWS' | translate }}</div>
  <!-- <table style="margin-top: 15px; margin-left: 15px;">
    <tr>
      <td><img src="../../assets/images/markernolunch.svg"></td>
      <td class="legend-text">{{ 'LEGEND_NO_CHECK' | translate }}</td>
      <td><img src="../../assets/images/markerlunch.svg"></td>
      <td class="legend-text" style="max-width: 95px; min-width: 95px;">{{ 'LEGEND_CHECK' | translate }}</td>
      <td style="text-align: right; min-width: calc(100vw - 315px)"><button mat-icon-button type="button" tabindex="-1"
          (click)="goToSettings()" color="primary">
          <mat-icon style="font-size: 40px; height: 40px; width: 40px; margin-top: 10px;">settings</mat-icon>
        </button></td>
    </tr>
  </table> -->
</div>