import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  activeNav = new BehaviorSubject<string>('map-view');
  currentMapItems = new BehaviorSubject<any[]>([]);
  selectedLocation = new BehaviorSubject<any>(null);
  searchActive = new BehaviorSubject<boolean>(false);
  currentNews = new BehaviorSubject<any>(null);

  constructor() { }
}
