import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

//fonts awesome
import { faStar as faStarLight } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { faGift } from '@fortawesome/pro-regular-svg-icons';

//services
import { NavService } from 'src/app/services/nav.service';
import { FavouritesService } from 'src/app/services/favourites.service';


@Component({
  selector: 'locations-list',
  templateUrl: './locations-list.component.html',
  styleUrls: ['./locations-list.component.scss']
})
export class LocationsListComponent implements OnInit {
  @Input() displayItems: Array<any>;

  faGift = faGift;
  faStarLight = faStarLight;
  faStarSolid = faStarSolid;

  hasData = false;
  center = {
    lat: 47.37743292251955,
    lng: 8.539431402682172,
  }

  currentLang = 'de';
  session: string;
  loading = false;

  constructor(
    private navService: NavService,
    private router: Router,
    private favouritesService: FavouritesService
  ) { }

  ngOnInit(): void {
    this.session = localStorage.getItem('session');
    navigator.geolocation.getCurrentPosition((position) => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
    });
  }

  goToDetails(item: any, groupEntry: any) {
    const object = {
      item: item,
      groupEntry: groupEntry
    }
    this.navService.selectedLocation.next(object);
    this.router.navigateByUrl('details');
  }

  showMap(item: { lat: any; long: any; }) {
    const destination = {
      lat: item.lat,
      long: item.long
    }

    const link = "https://maps.google.com/maps?saddr=" + this.center.lat + "," + this.center.lng + "&daddr=" + destination.lat + "," + destination.long;
    window.open(link, "_blank");
  }

  // updateLocations(location) {
  //   location.isFavourite = !location.isFavourite;

  //   const locations = this.navService.currentMapItems.getValue();
  //   const currentLocationIndex = locations.findIndex(item => item.id === location.id);

  //   if (currentLocationIndex !== -1) {
  //     locations.splice(currentLocationIndex, 1, location);
  //     this.navService.currentMapItems.next(locations);
  //   }

  //   const favLocations = this.favouritesService.favourites.getValue();
  //   const favLocationIndex = favLocations.findIndex(item => item.id === location.id);

  //   if (favLocationIndex !== -1) {
  //     favLocations.splice(favLocationIndex, 1);
  //     this.favouritesService.favouritesIds.splice(favLocationIndex, 1)
  //   } else {
  //     favLocations.push({...location, isFavourite: true});
  //     this.favouritesService.favouritesIds.push(location.id);
  //   }

  //   this.favouritesService.favourites.next(favLocations);
  // }

  // toggleFavourite(item: any) {
  //   this.loading = true;
  //   if (item.isFavourite) {
  //     this.favouritesService.removeFavourite({ session: this.session, fenaco_client: item.id }).subscribe(_ => {
  //       this.updateLocations(item);
  //       this.loading = false;
  //     }, err => {
  //       this.loading = false;
  //     });
  //   }

  //   if (!item.isFavourite) {
  //     this.favouritesService.addFavourite({ session: this.session, fenaco_client: item.id }).subscribe(_ => {
  //       this.updateLocations(item);
  //       this.loading = false;
  //     }, err => {
  //       this.loading = false;
  //     });
  //   }
  // }

  toggleFavourite(item: any) {
    this.favouritesService.toggleFavourite(item);
  }
}
