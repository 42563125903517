import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { NavService } from '../services/nav.service';
import { RequestService } from '../services/request.service';
import MarkerClusterer from "@google/markerclusterer"
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../dialogs/error-dialog/error-dialog.component';
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { faStar as faStarLight } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';

import Swiper, { Navigation, Pagination, SwiperOptions, Virtual, Autoplay } from 'swiper';
import { FavouritesService } from '../services/favourites.service';

Swiper.use([Navigation, Pagination, Virtual, Autoplay]);


interface NewsModel {
  id: number;
  title: string;
  short_desc: string;
  desc: string;
  media: Array<{
    desc: string,
    path: string,
    size: string,
    create: string,
    is_main: boolean,
    filename: string,
    language: string,
    file_type: string
  }>;
  start_date: string;
  end_date: string;
  create_date: string;
}

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements AfterViewInit, AfterContentChecked, OnDestroy {
  faStarLight = faStarLight;
  faStarSolid = faStarSolid;
  faAngleRight = faAngleRight;
  myLatLng = {
    lng: 0,
    lat: 0
  }
  myLabel = {
    color: 'red',
    text: 'Marker label',
  }

  gMap: any;
  vh = window.innerHeight * 0.01;
  mapPosition = {
    minlat: 0,
    maxlat: 0,
    minlong: 0,
    maxlong: 0
  }

  onlyluncheck = false;
  onlyvouchers = false;
  currentCenter = null;
  testMap = null;
  testOverlay = null;

  zoom = 14;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 10,
    minZoom: 1,
    streetViewControl: false,
    disableDefaultUI: true
  };
  loadingComplete = false;
  markersLoaded = false;
  availableMarkers: any = [];
  selectedMarker = null;
  markerStyle = [
    {
      textColor: 'black',
      url: '../../assets/images/cluster1.png',
      height: 34,
      width: 34,
    },
    {
      textColor: 'black',
      url: '../../assets/images/cluster2.png',
      height: 34,
      width: 34,
    },
    {
      textColor: 'black',
      url: '../../assets/images/cluster3.png',
      height: 34,
      width: 34,
    },
    {
      textColor: 'black',
      url: '../../assets/images/cluster4.png',
      height: 34,
      width: 34,
    },
    {
      textColor: 'black',
      url: '../../assets/images/cluster5.png',
      height: 34,
      width: 34,
    }
  ];
  markerOptions = {
    gridSize: 34,
    maxZoom: 20,
    styles: this.markerStyle
  }

  news: Array<NewsModel> = [];
  config: SwiperOptions = {
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    effect: 'slide',
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: false,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
    },
  };

  private waiting: any = null;

  public isNewsLoaded: boolean = false;

  constructor(
    private navService: NavService,
    private request: RequestService,
    private router: Router,
    private dialog: MatDialog,
    private favouritesService: FavouritesService,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2
  ) { }

  async ngAfterViewInit() {
    this.renderer.addClass(document.body, 'no-scroll')
    console.log('enter');
    this.navService.searchActive.next(false);
    this.navService.activeNav.next('map-view');
    navigator.geolocation.getCurrentPosition((position) => {
      this.getOnlineMap(position);
    }, (error) => {
      this.getOfflineMap()
      console.log(error, 'not enabled');
    });

    const lang = localStorage.getItem('currentLang') || 'de';
    try {
      const res = await this.request.getNews().toPromise();
      for (const item of res) {
        if (item.title[lang]) {
          const localizedItem = {
            ...item,
            title: decodeURIComponent(item.title[lang]),
            short_desc: decodeURIComponent(item.short_desc[lang]),
            desc: decodeURIComponent(item.desc[lang])
          }
          this.news.push(localizedItem);
        }
      }
      this.isNewsLoaded = true;
    } catch (err) {
      this.isNewsLoaded = true;
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges()
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'no-scroll')
  }


  getOfflineMap() {
    this.markersLoaded = false;
    console.log('enter offline');
    this.myLatLng.lng = 8.539431402682172;
    this.myLatLng.lat = 47.37743292251955;
    if (localStorage.getItem('center')) {
      this.center = JSON.parse(localStorage.getItem('center'));
    } else {
      this.center = {
        lat: this.myLatLng.lat,
        lng: this.myLatLng.lng,
      }
    }
    if (localStorage.getItem('zoom')) {
      this.zoom = Number(localStorage.getItem('zoom'));
    } else {
      this.zoom = 14;
    }
    const map = new google.maps.Map(document.getElementById('map'), {
      center: this.center,
      zoom: this.zoom,
      maxZoom: 20,
      minZoom: 8,
      streetViewControl: false,
      disableDefaultUI: true,
      scrollwheel: true,
      zoomControl: true,
    });
    this.testMap = map;
    const overlay = new google.maps.OverlayView();
    overlay.draw = function () { };
    overlay.setMap(map);
    this.loadingComplete = true;
    document.getElementById('map').setAttribute('visible', 'true');
    document.getElementById('map').style.setProperty('--vh', `${this.vh}px`);
    setTimeout(() => {
      this.setEventListeners(map, overlay);
      this.setMarkers(map, overlay);
    }, 1000);
    console.log('offline');
  }

  getOnlineMap(position) {
    this.markersLoaded = false;
    console.log('enter online');
    this.myLatLng.lng = position.coords.longitude;
    this.myLatLng.lat = position.coords.latitude;
    if (localStorage.getItem('center')) {
      this.center = JSON.parse(localStorage.getItem('center'));
    } else {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
    }
    if (localStorage.getItem('zoom')) {
      this.zoom = Number(localStorage.getItem('zoom'));
    } else {
      this.zoom = 14;
    }
    console.log(document.getElementById('map'));

    const map = new google.maps.Map(document.getElementById('map'), {
      center: this.center,
      zoom: this.zoom,
      maxZoom: 20,
      minZoom: 8,
      streetViewControl: false,
      disableDefaultUI: true,
      scrollwheel: true,
      zoomControl: true,
    });

    const overlay = new google.maps.OverlayView();
    overlay.draw = function () { };
    overlay.setMap(map);
    new google.maps.Marker({
      position: this.myLatLng,
      icon: '../../assets/images/currentlocation.svg',
      map
    });
    this.loadingComplete = true;
    document.getElementById('map').setAttribute('visible', 'true');
    document.getElementById('map').style.setProperty('--vh', `${this.vh}px`);
    setTimeout(() => {
      this.setEventListeners(map, overlay);
      this.setMarkers(map, overlay);
    }, 1000);
  }

  setMarkers(map, overlay) {
    console.log(this.availableMarkers);
    console.log(this.center);
    if (localStorage.getItem('position')) {
      this.mapPosition = JSON.parse(localStorage.getItem('position'));
    } else {
      this.mapPosition.minlat = map.getBounds().getSouthWest().lat();
      this.mapPosition.maxlat = map.getBounds().getNorthEast().lat();
      this.mapPosition.minlong = map.getBounds().getSouthWest().lng();
      this.mapPosition.maxlong = map.getBounds().getNorthEast().lng();
    }
    localStorage.removeItem('position');
    localStorage.removeItem('zoom');
    localStorage.removeItem('center');
    console.log(this.mapPosition);
    const body = {
      minlat: this.mapPosition.minlat,
      maxlat: this.mapPosition.maxlat,
      minlong: this.mapPosition.minlong,
      maxlong: this.mapPosition.maxlong,
      onlyluncheck: this.onlyluncheck,
      onlyvouchers: this.onlyvouchers
    }
    if (this.waiting) {
      clearTimeout(this.waiting);
    }
    this.waiting = setTimeout(() => {
      this.request.getMapMarkers(body).subscribe(
        (data) => {
          this.availableMarkers = [];
          this.navService.currentMapItems.next(data);
          data.forEach((element: { lat: any; long: any; company: any; lunchcheck: any, group: any, voucher: any }) => {
            const temp = element;
            let marker = null;

            marker = new google.maps.Marker({
              position: {
                lat: element.lat,
                lng: element.long,
              },

              icon: '../../assets/images/markernolunch.svg',
              clickable: true,
              title: 'nolunchcheck'
            });

            if (element.voucher && element.voucher.length) {
              marker = new google.maps.Marker({
                position: {
                  lat: element.lat,
                  lng: element.long,
                },

                icon: '../../assets/images/voucherlocation.svg',
                clickable: true,
                title: 'voucher'
              });
            }

            this.availableMarkers.push(marker);
            marker.addListener('click', (event: any) => {
              this.selectedMarker = temp;
            });
            // marker.setMap(map);
          });
          this.markersLoaded = true;
          new MarkerClusterer(map, this.availableMarkers, this.markerOptions);

          this.loadingComplete = true;
        }, (err) => {
          this.markersLoaded = true;
          this.loadingComplete = true;
          this.dialog.open(ErrorDialogComponent, {
            data: {}
          });
          console.log(err);
        }
      );


    }, 300);
  }

  setEventListeners(map, overlay) {
    google.maps.event.addListener(map, 'idle', () => {
      console.log('idle');
      this.zoom = map.zoom;
      this.center = map.center;
      this.selectedMarker = null;
      this.testMap = map;
      this.loadingComplete = false;
      this.testOverlay = overlay
      this.setMarkers(map, overlay);
    });

  }

  goToDetails(item: any, groupEntry: any) {
    console.log('test')
    localStorage.setItem('center', JSON.stringify(this.center));
    localStorage.setItem('position', JSON.stringify(this.mapPosition));
    localStorage.setItem('zoom', this.zoom.toString());
    const object = {
      item: item,
      groupEntry: groupEntry
    }
    this.navService.selectedLocation.next(object);
    this.router.navigateByUrl('details');
  }

  showMap(item: { lat: any; long: any; }) {
    this.loadingComplete = false;
    const destination = {
      lat: item.lat,
      long: item.long
    }

    navigator.geolocation.getCurrentPosition((position) => {
      this.loadingComplete = true;
      var link = "https://maps.google.com/maps?saddr=" + position.coords.latitude + "," + position.coords.longitude + "&daddr=" + destination.lat + "," + destination.long;
      window.open(link, "_blank");
    });
    /*const current = this.center;
    console.log(current);
    var link = "https://maps.google.com/maps?saddr=" + current.lat + "," + current.lng + "&daddr=" + destination.lat + "," + destination.long;
    window.open(link, "_blank");*/
  }

  removeSelected() {
    this.selectedMarker = null;
  }


  markerChange(event) {
    this.availableMarkers = [];
    if (this.testMap) {
      localStorage.setItem('center', JSON.stringify(this.testMap.getCenter()));
    }
    navigator.geolocation.getCurrentPosition((position) => {
      this.getOnlineMap(position);
    }, (error) => {
      this.getOfflineMap()
      console.log(error, 'not enabled');
    });
  }

  goToNewsDetails(item: NewsModel) {
    this.navService.currentNews.next(item);
    this.router.navigateByUrl('news');
  }

  toggleFavourite() {
    this.favouritesService.toggleFavourite(this.selectedMarker);
  }
}
