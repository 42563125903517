<div style="display: flex; justify-content: center; margin-top: 50%;" *ngIf="!loadingComplete">
  <mat-spinner></mat-spinner>
</div>
<div id="mark-details" class="marker_overlay" *ngIf="loadingComplete">
  <div style="margin-top: 15px; margin-left: 15px; margin-right: 15px; text-align: center;">
    <div>{{ 'OPEN_DIRECTIONS' | translate }}</div>
    <div style="margin-bottom: 10px; margin-top: 10px;">
    </div>
    <div>
      <button mat-button class="map-button" (click)="showMap()" style="font-size: 12px;">
        <mat-icon style="margin-right: 10px; font-size: 12px;" svgIcon="route_icon_map"></mat-icon>{{ 'ROUTE_BUTTON' |
        translate }}
      </button>
    </div>
  </div>
</div>
<div id="map-details" style="height: calc(50vh - 80px); margin-top: 70px;" visible="false">
</div>
<div class="action-box">
  <div *ngIf="loadingComplete" style="margin: 10px 30px 10px;">
    <div style="display: flex; justify-content:space-between;">
      <div>
        <p *ngIf="!selectedLocation.groupEntry && selectedLocation.item.group.length === 0">
          {{selectedLocation.item.company}}
        </p>
        <ng-container *ngIf="!selectedLocation.groupEntry && selectedLocation.item.group.length > 0">
          <div *ngFor="let g of selectedLocation.item.group" style="display: flex;">
            <p style="min-width: 250px;">{{g.company}}</p>
            <!-- <img *ngIf="g.lunchcheck" src="../../assets/images/lunch_check_blue.svg"> -->
          </div>
        </ng-container>
        <p *ngIf="selectedLocation.groupEntry">
          {{selectedLocation.groupEntry.company}}
        </p>
        <p>
          {{selectedLocation.item.address}}, {{selectedLocation.item.postcode}} {{selectedLocation.item.city}}
        </p>
      </div>
      <!-- <div *ngIf="!selectedLocation.groupEntry && selectedLocation.item.group.length === 0">
        <img *ngIf="selectedLocation.item.lunchcheck" style="margin-top: 30px;"
          src="../../assets/images/lunch_check_blue.svg">
      </div>
      <div *ngIf="selectedLocation.groupEntry">
        <img *ngIf="selectedLocation.groupEntry.lunchcheck" style="margin-top: 30px;"
          src="../../assets/images/lunch_check_blue.svg">
      </div> -->
    </div>
    <div>
      <button mat-button class="list-button" style="margin-right: 10px;" (click)="showMap()">
        <mat-icon style="margin-right: 10px;" svgIcon="route_icon"></mat-icon>{{ 'ROUTE_BUTTON' | translate }}
      </button>
      <button mat-button class="list-button fav-button" [ngClass]="{'favourite': selectedLocation.item.isFavourite}"
        style="margin-right: 10px;" (click)="toggleFavourite()">
        <fa-icon class="star-icon" [icon]="selectedLocation.item.isFavourite ? faStarSolid : faStarLight"></fa-icon> {{
        'LIKE_BUTTON' | translate }}
      </button>
      <a style="color: #006a3a;"
        *ngIf="selectedLocation.item.seminar_url && selectedLocation.item.seminar_url !== 'NaN'"
        [href]="selectedLocation.item.seminar_url">{{'FURTHER_INFO' | translate}}</a>
      <!--<div style="margin-top: 25px;"></div>-->
    </div>
    <div *ngIf="showGeoButton" style="margin-top: 25px;">
      <button mat-flat-button style="margin-right: 10px;" (click)="showSettings()" color="primary">
        <mat-icon style="margin-right: 10px;">near_me</mat-icon>{{ 'SETTINGS_BUTTON' | translate }}
      </button>
    </div>
  </div>
</div>
<div *ngIf="voucher" #voucherBox class="voucher-info">
  <div class="voucner-icon" (click)="scrollToElement(voucherBox)">
    <fa-icon class="gift-icon" [icon]="faGift"></fa-icon>
  </div>
  <div class="title" [innerHTML]="voucher.title"></div>
  <div class="short-description" [innerHTML]="voucher.short_desc"></div>
  <button *ngIf="voucher.media" class="download-btn" type="button" tabindex="-1" mat-button color="primary"
    (click)="download()">
    {{ 'DOWNLOAD_BUTTON' | translate }}
  </button>
  <div class="description" [innerHTML]="voucher.desc"></div>
</div>