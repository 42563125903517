import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

//services
import { NavService } from 'src/app/services/nav.service';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit, OnDestroy {
  displayItems: Array<any> = [];

  //subscriptions
  private currentMapItems$: Subscription;

  constructor(
    private navService: NavService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.navService.activeNav.next('list-view');

    if (this.navService.currentMapItems.value.length) {
      this.currentMapItems$ = this.navService.currentMapItems.subscribe(res => {
        this.displayItems = res;
      })
    } else {
      this.router.navigateByUrl('map-view');
    }
  }

  ngOnDestroy(): void {
    if (this.currentMapItems$) this.currentMapItems$.unsubscribe();
  }
}
