<div style="text-align: center; height: 60px;">
  <img style="margin-top: 10px;" src="../../assets/images/fenaco_text.svg">
</div>
<div class="info-container">
  <div style="height: 100px;">
    <img width="150px" src="../../assets/images/atable_gastro-app.png">
  </div>
  <div style="margin-top: 25px;"><h3>{{ 'INFO_WELCOME' | translate }}</h3></div>
  <div style="margin-top: 25px;">{{ 'INFO_TEXT' | translate }}</div>
  <div style="margin-top: 25px; white-space:pre-wrap;">{{ 'INFO_GREETING' | translate }}</div>
  <div style="margin-top: 50px;">
    <button mat-flat-button style="color: #006a3a;" type="button" tabindex="-1" (click)="next()">{{ 'INFO_VIEW_BUTTON' | translate }}</button>
  </div>
  <div style="margin-top: 50px;">
    <mat-checkbox class="example-margin" (change)="clickCheckbox($event)">{{ 'INFO_VIEW_CHECKBOX' | translate }}</mat-checkbox>
  </div>
</div>

