import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavService } from '../services/nav.service';

@Component({
  selector: 'app-add-ios',
  templateUrl: './add-ios.component.html',
  styleUrls: ['./add-ios.component.scss']
})
export class AddIosComponent implements OnInit {

  constructor(
    private navService: NavService,
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log('enter ios');
    this.navService.activeNav.next('ios');
    setTimeout(() => {
      if (localStorage.getItem('info-shown')) {
        this.router.navigateByUrl('/map-view');
      } else {
        this.router.navigateByUrl('/info');
      }
    }, 6000);
  }
}
