import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

//components
import { SettingsDialogComponent } from 'src/app/dialogs/settings-dialog/settings-dialog.component';

//services
import { NavService } from 'src/app/services/nav.service';
import { FavouritesService } from 'src/app/services/favourites.service';
import { RequestService } from 'src/app/services/request.service';

//fonts awesome
import { faGift } from '@fortawesome/pro-regular-svg-icons';
import { faStar as faStarLight } from '@fortawesome/pro-light-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.scss']
})
export class DetailViewComponent implements OnInit {
  faStarLight = faStarLight;
  faStarSolid = faStarSolid;
  faGift = faGift;

  selectedLocation = <any>null;
  loadingComplete = false;
  showGeoButton = false;

  voucher = null;

  myLatLng = {
    lng: 0,
    lat: 0
  }

  selectedLatLng = {
    lng: 0,
    lat: 0
  }

  myOptions = {
    icon: '../../assets/images/current_location.svg'
  }

  selectedOptions = {
    icon: '../../assets/images/marker.svg'
  }

  mapPosition = {
    minlat: 0,
    maxlat: 0,
    minlong: 0,
    maxlong: 0
  }

  zoom = 14;
  center!: google.maps.LatLngLiteral;
  options: google.maps.MapOptions = {
    mapTypeId: 'roadmap',
    zoomControl: false,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    maxZoom: 20,
    minZoom: 8,
    streetViewControl: false,
    disableDefaultUI: true
  };

  constructor(
    private navService: NavService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    private requestService: RequestService,
    private favouritesService: FavouritesService
  ) { }

  ngOnInit(): void {
    this.navService.activeNav.next('detail-view');
    this.selectedLocation = this.navService.selectedLocation.value;

    if (this.selectedLocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.getOnlineMap(position)
      }, (error) => {
        this.showGeoButton = true;
        this.getOfflineMap();
      });

      const [voucher] = this.selectedLocation.item.voucher;

      if (voucher) {
        const lang = localStorage.getItem('currentLang') || 'de';
        let [media] = voucher.media.filter(item => item.language === lang);

        if (!media) {
          [media] = voucher.media.filter(item => item.language === 'de');
        }

        if (!media) {
          [media] = voucher.media;
        }

        this.voucher = {
          ...voucher,
          title: decodeURIComponent(voucher.title[lang]),
          short_desc: decodeURIComponent(voucher.short_desc[lang]),
          desc: decodeURIComponent(voucher.desc[lang]),
          media: media
        }
      }

    } else {
      this.router.navigateByUrl('map-view');
    }

    navigator.permissions.query({ name: 'geolocation' }).then((result) => {
      if (result.state == 'granted') {
        this.showGeoButton = false;
      } else if (result.state == 'prompt') {
        this.showGeoButton = false;
      } else if (result.state == 'denied') {
        this.showGeoButton = true;
      }
    });
  }

  scrollToElement(element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    // const position = window.innerHeight - 220;
    // window.scrollTo({
    //   top: position,
    //   behavior: 'smooth'
    // });
  }

  getOnlineMap(position) {
    this.myLatLng.lng = position.coords.longitude;
    this.myLatLng.lat = position.coords.latitude;
    this.center = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    }
    console.log(this.myLatLng);
    const map = new google.maps.Map(document.getElementById('map-details'), {
      center: this.center,
      zoom: this.zoom,
      maxZoom: 20,
      minZoom: 8,
      streetViewControl: false,
      disableDefaultUI: true,
      scrollwheel: true,
      zoomControl: false,
    });

    const overlay = new google.maps.OverlayView();
    overlay.draw = function () { };
    overlay.setMap(map);

    new google.maps.Marker({
      position: this.myLatLng,
      icon: '../../assets/images/currentlocation.svg',
      map
    });

    this.selectedLatLng.lat = this.selectedLocation!.item.lat;
    this.selectedLatLng.lng = this.selectedLocation.item.long;

    new google.maps.Marker({
      position: this.selectedLatLng,
      icon: '../../assets/images/markernolunch.svg',
      map
    });

    if (this.selectedLocation.item && this.selectedLocation.item.voucher && this.selectedLocation.item.voucher.length) {
      new google.maps.Marker({
        position: this.selectedLatLng,
        icon: '../../assets/images/voucherlocation.svg',
        title: 'voucher',
        map
      });
    }

    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({ polylineOptions: { strokeColor: "#006a3a" } });

    directionsRenderer.setMap(map);
    directionsRenderer.setOptions({ suppressMarkers: true });
    directionsService.route(
      {
        origin: this.myLatLng,
        destination: this.selectedLatLng,
        travelMode: google.maps.TravelMode.DRIVING,
      }, (response, status) => {
        if (status === 'OK') {
          directionsRenderer.setDirections(response);
        } else {
          switch (status) {
            case 'NOT_FOUND':
              window.alert(this.translate.instant('MAP_ERROR_NOT_FOUND'));
              break;
            case 'ZERO_RESULTS':
              window.alert(this.translate.instant('MAP_ERROR_NOT_FOUND'));
              break;
            default: {
              window.alert(status);
              break;
            }
          }

        }
      }
    );
    this.loadingComplete = true;
  }

  getOfflineMap() {
    this.selectedLocation = this.navService.selectedLocation.value;
    this.selectedLatLng.lat = this.selectedLocation.item.lat;
    this.selectedLatLng.lng = this.selectedLocation.item.long;
    this.center = {
      lat: this.selectedLatLng.lat,
      lng: this.selectedLatLng.lng,
    }
    const map = new google.maps.Map(document.getElementById('map-details'), {
      center: this.center,
      zoom: this.zoom,
      maxZoom: 20,
      minZoom: 8,
      streetViewControl: false,
      disableDefaultUI: true,
      scrollwheel: true,
      zoomControl: false,
    });

    new google.maps.Marker({
      position: this.selectedLatLng,
      icon: '../../assets/images/markernolunch.svg',
      map
    });

    if (this.selectedLocation.item && this.selectedLocation.item.voucher && this.selectedLocation.item.voucher.length) {
      new google.maps.Marker({
        position: this.selectedLatLng,
        icon: '../../assets/images/voucherlocation.svg',
        title: 'voucher',
        map
      });
    }

    this.loadingComplete = true;
    document.getElementById('map-details').setAttribute('visible', 'true');
  }

  showMap() {
    const destination = {
      lat: this.selectedLocation.item.lat,
      long: this.selectedLocation.item.long
    }
    let current = {
      lng: 8.539431402682172,
      lat: 47.37743292251955
    }
    if (this.center.lat !== this.selectedLocation.item.lat || this.center.lng !== this.selectedLocation.item.long) {
      current = this.center;
    }

    var link = "https://maps.google.com/maps?saddr=" + current.lat + "," + current.lng + "&daddr=" + destination.lat + "," + destination.long;
    window.open(link, "_blank");
  }

  showSettings() {
    var userAgent = navigator.userAgent || navigator.vendor;
    var device = '';

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      console.log('Windows Phone"');
      device = 'Windows Phone'
    }

    if (/android/i.test(userAgent)) {
      console.log('Android');
      device = 'Android'
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      console.log('ios');
      device = 'ios';

    }

    this.dialog.open(SettingsDialogComponent, {
      data: {
        deviceType: device
      }
    });
  }

  download() {
    this.requestService.downloadFile(this.voucher.media.path).then(res => {
      saveAs(res, this.voucher.media.filename);
    });
  }

  toggleFavourite() {
    this.favouritesService.toggleFavourite(this.selectedLocation.item);
  }
}
