<div class="imageIOS">
  <img src="../../assets/images/fenaco_text.svg">
</div>
<div class="steps">
  <div style="display: flex; width: 80%;">
    <img class="step-icon" src="../../assets/images/step1.svg">
    <p class="text">{{'ADD_IOS_ONE' | translate}}</p>
  </div>
</div>
<div class="steps" style="margin-top: 0px;">
  <div style="display: flex; width: 80%;">
    <img class="step-icon" src="../../assets/images/step2.svg">
    <p class="text">{{'ADD_IOS_TWO' | translate}}</p>
  </div>
</div>
<div class="bottom-container">
  <div style="display: flex; justify-content: center;">
    <p style="width: 50%; margin-top: 35px;">{{ 'ADD_IOS_TEXT' | translate }}</p>
  </div>
  <div style="position: fixed; width: 100%; bottom: 0px; display: block;">
    <div>
      {{ 'ADD_IOS_CLICK' | translate }}
    </div>
    <div>
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
  </div>
</div>

