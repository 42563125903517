import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { FavouritesService } from './services/favourites.service';
import { NavService } from './services/nav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Gastro App';
  showIOSPWAPopup = false;
  navigatorObj: any = window.navigator;

  constructor(
    private translate: TranslateService,
    private navService: NavService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private favouritesService: FavouritesService
  ) {
    const lang = 'de';
    this.translate.setDefaultLang(lang);
    const savedLang = localStorage.getItem('currentLang');

    if (savedLang) {
      this.translate.use(savedLang);
    } else {
      if (navigator.language.split('-')[0] === 'de') {
        this.translate.use('de');
      }
      else if (navigator.language.split('-')[0] === 'fr') {
        this.translate.use('fr');
      }
      // else if (navigator.language.split('-')[0] === 'en') {
      //   this.translate.use('en');
      // } 
      else {
        this.translate.use('de');
      }
    }

    this.matIconRegistry.addSvgIcon(
      'map_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/map-icon.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'route_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/route.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'route_icon_map',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/route_map.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'present_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/voucherlocation.svg')
    );
    const needs = this.needsToSeeIOSPWAPrompt();
    this.showIOSPWAPopup = needs;
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log(e);
    });
  }

  async ngOnInit() {
    const session = localStorage.getItem('session');

    if (!session) {
      const guid = this.favouritesService.newGuid();
      localStorage.setItem('session', guid);
      try {
        await this.favouritesService.saveSession(guid).toPromise();
      } catch (err) {
        console.log(err);
      }
    }

    if (session) {
      this.favouritesService.getFavourites(session).subscribe();
    }

    
  }

  needsToSeeIOSPWAPrompt() {
    if (this.navigatorObj.standalone) {
      return false;
    }
    const lastPrompt = localStorage.getItem('pwaPromptClosed');
    const isApple = ['iPhone', 'iPad', 'iPod'].includes(navigator.platform);
    return !lastPrompt && isApple;
  }
}
