<div *ngIf="loading"
  style="display: flex; justify-content: center; margin-top: 50%; margin-left: calc(50% - 50px); z-index: 999; position: absolute;">
  <mat-spinner></mat-spinner>
</div>
<div style="margin-top: 70px; margin-left: 8px; margin-right: 8px;">
  <ng-container *ngFor="let item of displayItems">
    <ng-container>
      <div style="margin-top: 10px; margin-bottom: 10px; ">
        <div style="display: flex; justify-content:space-between;">
          <div>
            <p>
              {{item.company}}
            </p>
            <p>
              {{item.address}}, {{item.postcode}} {{item.city}}
            </p>
          </div>
          <div class="additional-wrap">
            <div class="fav-wrap" (click)="toggleFavourite(item)">
              <fa-icon class="star-icon" [icon]="item.isFavourite ? faStarSolid : faStarLight"></fa-icon>
            </div>
            <div *ngIf="item.voucher && item.voucher.length">
              <fa-icon class="gift-icon" [icon]="faGift"></fa-icon>
            </div>
            <div>
              <img height="48px" *ngIf="item.is_hotel && currentLang !== 'fr'" style="margin-top: 30px;"
                src="../../assets/images/hotel.svg">
              <img height="48px" *ngIf="item.is_hotel && currentLang === 'fr'" style="margin-top: 30px;"
                src="../../assets/images/hotel_fr.svg">
            </div>
            <div>
              <img height="48px" *ngIf="item.is_seminar && currentLang !== 'fr'" style="margin-top: 30px;"
                src="../../assets/images/seminar.svg">
              <img height="48px" *ngIf="item.is_seminar && currentLang === 'fr'" style="margin-top: 30px;"
                src="../../assets/images/seminar_fr.svg">
            </div>
          </div>
        </div>
        <div>
          <button mat-button class="list-button" style="margin-right: 10px;" (click)="showMap(item)">
            <mat-icon style="margin-right: 10px;" svgIcon="route_icon"></mat-icon>{{ 'ROUTE_BUTTON' | translate }}
          </button>
          <button mat-button class="list-button" (click)="goToDetails(item, null)">
            <mat-icon style="margin-right: 10px;">info_outline</mat-icon>{{ 'INFO_BUTTON' | translate }}
          </button>
        </div>
      </div>
      <mat-divider></mat-divider>
    </ng-container>
  </ng-container>
</div>