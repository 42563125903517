<button type="button" mat-flat-button class="close" (click)="close()" tabindex="-1">
  <mat-icon>close</mat-icon>
</button>
<div *ngIf="data.deviceType === 'Android'">
  <div class="steps">
    <h4 class="description">{{ 'SETTINGS_TEXT_ANDROID' | translate }}</h4>
  </div>
  <div class="steps">
    <img class="step-icon" src="../../assets/images/step1.svg">
    <p class="text">{{'SETTINGS_STEP_ONE_ANDROID' | translate}}</p>
  </div>
  <div class="steps">
    <img class="step-icon" src="../../assets/images/step2.svg">
    <p class="text">{{ 'SETTINGS_STEP_TWO_ANDROID' | translate }}</p>
  </div>
  <div class="steps">
    <img class="step-icon" src="../../assets/images/step3.svg">
    <p class="text">{{ 'SETTINGS_STEP_THREE_ANDROID' | translate }}</p>
  </div>
  <div class="steps">
    <img class="step-icon" src="../../assets/images/step4.svg">
    <p class="text">{{ 'SETTINGS_STEP_FOUR_ANDROID' | translate }}</p>
  </div>
</div>
<div *ngIf="data.deviceType === 'ios'">
  <div class="steps">
    <h4 class="description">{{ 'SETTINGS_TEXT_IOS' | translate }}</h4>
  </div>
  <div class="steps">
    <img class="step-icon" src="../../assets/images/step1.svg">
    <p class="text">{{ 'SETTINGS_STEP_ONE_IOS' | translate }}</p>
  </div>
  <div class="steps">
    <img class="step-icon" src="../../assets/images/step2.svg">
    <p class="text">{{ 'SETTINGS_STEP_TWO_IOS' | translate }}</p>
  </div>
  <div class="steps">
    <img class="step-icon" src="../../assets/images/step3.svg">
    <p class="text">{{ 'SETTINGS_STEP_THREE_IOS' | translate }}</p>
  </div>
  <div class="steps">
    <img class="step-icon" src="../../assets/images/step4.svg">
    <p class="text">{{ 'SETTINGS_STEP_FOUR_IOS' | translate }}</p>
  </div>
