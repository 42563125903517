import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

//services
import { NavService } from 'src/app/services/nav.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  langs = [];
  constructor(
    private translate: TranslateService,
    private navService: NavService
  ) {
    this.langs = [
      {
        value: 'de',
        text: 'Deutsch'
      },
      // {
      //   value: 'en',
      //   text: 'English'
      // },
      {
        value: 'fr',
        text: 'Français'
      }
    ];
  }
  selectedLang = 'de';

  ngOnInit(): void {
    this.navService.activeNav.next('settings-view');
    this.selectedLang = this.translate.currentLang;
  }

  change(event) {
    this.translate.use(event.value);
    localStorage.setItem('currentLang', event.value);
  }
}
