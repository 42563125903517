import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public currentUser: any;
  loading = false;

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
  ) { }

  // tslint:disable-next-line: typedef
  setHeaders(headerType: string, authenticate: boolean) {
    const headersConf = <any>{};
    headersConf['Accept-Language'] = this.translateService.currentLang;
    if (headerType === 'json') {
      headersConf['Content-Type'] = 'application/json';
    } else if (headerType === 'json-x') {
      headersConf['Content-Type'] = 'application/json';
    } else if (headerType === 'form') {
      headersConf['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    } else if (headerType === 'multipart') {
      // headersConf['Content-Type'] = 'multipart/form-data';
    }
    return new HttpHeaders(headersConf);
  }

  get(path: string, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.get(environment.api_url + path, { headers });
  }

  patch(path: string, body: any, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.patch(environment.api_url + path, body, { headers });
  }

  post(path: string, body: any, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.post(environment.api_url + path, body, { headers });
  }

  put(path: string, body: any, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.put(environment.api_url + path, body, { headers });
  }

  delete(path: string, body: any, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.delete(environment.api_url + path, { headers });
  }

  deleteWithBody(path: string, body: any, headerType: string, authenticate: boolean): Observable<any> {
    const headers = this.setHeaders(headerType, authenticate);
    return this.http.request('DELETE', environment.api_url + path, { headers, body });
  }
}
