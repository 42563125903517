import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddIosComponent } from './add-ios/add-ios.component';
import { DetailNewsComponent } from './detail-news/detail-news.component';
import { DetailViewComponent } from './detail-view/detail-view.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { InfoComponent } from './info/info.component';
import { ListViewComponent } from './list-view/list-view.component';
import { MapViewComponent } from './map-view/map-view.component';
import { SearchViewComponent } from './search-view/search-view.component';
import { SettingsComponent } from './settings/settings.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';

const routes: Routes = [
  { path: '', component: SplashScreenComponent },
  { path: 'map-view', component: MapViewComponent },
  { path: 'list-view', component: ListViewComponent },
  { path: 'search', component: SearchViewComponent },
  { path: 'details', component: DetailViewComponent },
  { path: 'info', component: InfoComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'favourites', component: FavouritesComponent},
  { path: 'news', component: DetailNewsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
