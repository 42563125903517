import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

//components
import { ErrorDialogComponent } from 'src/app/dialogs/error-dialog/error-dialog.component';

//services
import { FavouritesService } from 'src/app/services/favourites.service';
import { NavService } from 'src/app/services/nav.service';
import { RequestService } from 'src/app/services/request.service';

@Component({
  selector: 'app-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.scss']
})
export class SearchViewComponent implements OnInit {

  body = {
    search: '',
    onlyluncheck: false,
    size: null,
    zipcode: '',
    canton: [],
    is_hotel: false,
    is_seminar: false,
    hotel_size: '',
    is_client: true
  }

  noData = false;

  states = [];
  selectedCanton = null;

  advancedOpen = false;
  selectAdvanced = [
    {
      type: 'Hotel'
    },
    {
      type: 'Seminar'
    }
  ];

  selectSize = [
    {
      value: 1,
      size: 'HOTEL_ONE'
    },
    {
      value: 2,
      size: 'HOTEL_TWO'
    },
    {
      value: 3,
      size: 'HOTEL_THREE'
    }
  ]

  constructor(
    private navService: NavService,
    private request: RequestService,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    private favouritesService: FavouritesService
  ) { }

  ngOnInit(): void {
    this.getCantons();
    this.navService.activeNav.next('search-view');
  }

  changeBodyValue() {
    this.body.is_client = !this.body.is_client;
    console.log(this.body);
  }

  getCantons() {
    console.log(this.translate.currentLang);
    this.request.getCantons().subscribe(
      (data) => {
        console.log(this.translate.currentLang);
        if (this.translate.currentLang === 'de') {
          this.states = data.cantons.canton_de.sort((a, b) => a.name > b.name ? 1 : -1);
        }
        else if (this.translate.currentLang === 'it') {
          this.states = data.cantons.canton_it.sort((a, b) => a.name > b.name ? 1 : -1);
        }
        else if (this.translate.currentLang === 'fr') {
          this.states = data.cantons.canton_fr.sort((a, b) => a.name > b.name ? 1 : -1);
        } else {
          this.states = data.cantons.canton_de.sort((a, b) => a.name > b.name ? 1 : -1);
        }
        //this.states = data;
      }, (err) => {
        console.log(err);
      }
    );
  }

  search() {
    this.noData = false;
    this.navService.searchActive.next(true);
    const body = {
      search: this.body.search,
      onlyluncheck: this.body.onlyluncheck
    }
    this.request.textSearch(body).subscribe(
      (data) => {
        if (data.result.length > 0) {
          this.navService.currentMapItems.next(data.result);
          this.router.navigateByUrl('list-view');
        } else {
          this.noData = true;
        }
      }, (err) => {
        this.dialog.open(ErrorDialogComponent, {
          data: {}
        });
        console.log(err);
      }
    );
  }

  searchEnter(event) {
    if (this.advancedOpen) {
      return;
    } else {
      this.search();
    }
  }

  searchAdvanced() {
    this.noData = false;
    this.navService.searchActive.next(true);
    let cantons = '';
    if (this.body.canton.length > 0) {
      for (let i = 0; i < this.body.canton.length; i++) {
        if (i === 0) {
          cantons = this.body.canton[i];
        } else {
          cantons += ',' + this.body.canton[i];
        }
      }
    }
    const body = {
      search: this.body.search,
      onlyluncheck: this.body.onlyluncheck,
      zipcode: this.body.zipcode ? this.body.zipcode.trim().replace(' ', '') : '',
      canton: cantons,
      is_hotel: this.body.is_hotel,
      is_seminar: this.body.is_seminar,
      hotel_size: this.body.hotel_size,
      is_client: this.body.is_client
    }
    this.request.advancedSearch(body).subscribe(
      (data) => {
        if (data.result.length > 0) {
          this.navService.currentMapItems.next(data.result);
          this.router.navigateByUrl('list-view');
        } else {
          this.noData = true;
        }
      }, (err) => {
        this.dialog.open(ErrorDialogComponent, {
          data: {}
        });
        console.log(err);
      }
    );
  }
  openAdvancedSearch() {
    this.advancedOpen = !this.advancedOpen;
  }

  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.code === "Enter" && this.advancedOpen) {
      this.searchAdvanced()
    }
  }
}
