import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { NavService } from './nav.service';

interface BodyModel {
  session: string,
  fenaco_client: number
}

@Injectable({
  providedIn: 'root'
})
export class FavouritesService {
  private session: string;
  public favouritesIds: Array<string> = [];
  public favourites: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);

  constructor(
    private apiService: ApiService,
    private navService: NavService
  ) { }

  getFavourites(session: string) {
    return this.apiService.get(`/api/user_session/${session}`, 'json', true).pipe(
      map(
        (data) => {
          this.session = session;
          this.favouritesIds = data.favorite.map(item => item.id);
          const favorites = data.favorite.map(item => {
            item.isFavourite = true;
            return item;
          })
          this.favourites.next(favorites);
          return data;
        }
      )
    );
  }

  addFavourite(body: BodyModel) {
    return this.apiService.put(`/api/user_session`, body, 'json', true).pipe(
      map(
        (data) => {
          return data;
        }
      )
    );
  }

  removeFavourite(body: BodyModel) {
    return this.apiService.deleteWithBody(`/api/user_session`, body, 'json', true).pipe(
      map(
        (data) => {
          return data;
        }
      )
    );
  }

  saveSession(session: string) {
    return this.apiService.post(`/api/user_session`, { session }, 'json', true).pipe(
      map(
        (data) => {
          this.session = session;
          return data;
        }
      )
    );
  }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      var r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  private updateLocations(location) {
    location.isFavourite = !location.isFavourite;

    const locations = this.navService.currentMapItems.getValue();
    const currentLocationIndex = locations.findIndex(item => item.id === location.id);

    if (currentLocationIndex !== -1) {
      locations.splice(currentLocationIndex, 1, location);
      this.navService.currentMapItems.next(locations);
    }

    const favLocations = this.favourites.getValue();
    const favLocationIndex = favLocations.findIndex(item => item.id === location.id);

    if (favLocationIndex !== -1) {
      favLocations.splice(favLocationIndex, 1);
      this.favouritesIds.splice(favLocationIndex, 1)
    } else {
      favLocations.push({ ...location, isFavourite: true });
      this.favouritesIds.push(location.id);
    }

    this.favourites.next(favLocations);
  }

  toggleFavourite(item: any) {
    if (item.isFavourite) {
      this.removeFavourite({ session: this.session, fenaco_client: item.id }).subscribe(_ => {
        this.updateLocations(item);
      });
    }

    if (!item.isFavourite) {
      this.addFavourite({ session: this.session, fenaco_client: item.id }).subscribe(_ => {
        this.updateLocations(item);
      });
    }
  }
}
